<template>
  <v-container class="mt-5 mb-15 px-10" fluid>
    <PageHeader>
      <template #header>
        <img
          v-if="pluginLogo"
          :src="pluginLogo"
          :alt="`${pluginName} logo`"
          height="32"
        >
        {{ installed ? 'Update ' : 'Install' }} {{ pluginName }} Plugin
      </template>
      <template #subheader>
        Please configure the following in order to install the {{ pluginName }} plugin.
      </template>
    </PageHeader>

    <validation-observer ref="autoImportConfigs">
      <AutomatedImportConfigurations
        :id="id"
        :plugin-data="pluginData"
        :import-configurations="importConfigurations"
        :import-type="importType"
        :file-extensions="fileExtensionOptions"
        @add-configuration="addImportConfiguration"
        @delete-configuration="deleteImportConfiguration"
        @update-configuration="updateImportValue"
      />
    </validation-observer>

    <v-row class="mt-12">
      <v-col cols="12" class="d-flex justify-end">
        <v-btn
          v-if="installed"
          class="custom-button custom-button--red-text px-8 mr-4"
          height="34px"
          depressed
          @click="deactivateDialog = true;"
        >
          Deactivate
        </v-btn>
        <v-btn
          class="custom-button custom-button--blue px-13"
          height="34px"
          depressed
          :block="$vuetify.breakpoint.xsOnly"
          @click="installPlugin(importConfigurations, false)"
        >
          {{ installed ? "Configure" : "Install" }}
        </v-btn>
      </v-col>
    </v-row>
    <DeactivateConfirmDialog
      v-if="deactivateDialog"
      :id="id"
      :plugin="pluginData"
      @dismiss="deactivateDialog = false;"
    />
  </v-container>
</template>

<script>
import importExportMixin from "@/utils/import-export-mixin.js";
import PageHeader from "@/sharedComponents/PageHeader";
import AutomatedImportConfigurations from "@/views/Plugins/components/AutomatedImportConfigurations";
import DeactivateConfirmDialog from "./components/DeactivateConfirmDialog";

export default {
  name: "AddSharedPlugin",
  components: {
    AutomatedImportConfigurations,
    PageHeader,
    DeactivateConfirmDialog,
  },
  mixins: [importExportMixin],
  props: {
    id: {
      default: () => null,
      type: [Number, String],
    },
    installed: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    deactivateDialog: false,
    pluginData: null,
    pluginLogo: null,
    pluginName: null,
    importType: null,
    fileExtensionOptions: [],
  }),
  created() {
    if (this.installed) {
      this.loadAccountPlugin();
    } else {
      this.getPluginDataById();
    }
  },
  methods: {
    async getPluginDataById() {
      const resp = await this.$rest.plugins.getPluginDataById(this.id);
      if (!resp.data) {
        return;
      }
      this.pluginLogo = require(`@/assets/plugins/${resp?.data?.image}`);
      this.pluginName = resp?.data?.name;

      const componentName = resp?.data?.componentName;
      this.importType = this.getSpecificPluginImportType(componentName);
      this.fileExtensionOptions = this.getSpecificPluginFileExtensionOptions(componentName);
    },
    async loadAccountPlugin() {
      const resp = await this.$rest.plugins.get_resource(this.id, {
        includeCredentials: true,
      });
      if (!resp.data) {
        return;
      }

      this.pluginData = resp.data;
      this.pluginLogo = require(`@/assets/plugins/${resp?.data?.plugin?.image}`);
      this.pluginName = resp?.data?.plugin?.name;

      const componentName = resp?.data?.plugin?.componentName;
      this.importType = this.getSpecificPluginImportType(componentName);
      this.fileExtensionOptions = this.getSpecificPluginFileExtensionOptions(componentName);

      if (resp.data.pluginConfiguration?.importConfigurations.length) {
        const credentials = resp.data.pluginConfiguration?.credentials;

        this.setupImportConfigurations(resp.data.pluginConfiguration?.importConfigurations, credentials);
      }
    },
    getSpecificPluginImportType(componentName) {
      switch (componentName) {
        case 'AddPluginBioPharm':
          return 'biopharm_action';
        case 'AddPluginBulletinHealthcare':
          return 'bulletin_healthcare_action';
        case 'AddPluginDoximity':
          return 'doximity_action';
        case 'AddPluginEHealthcareSolutions':
          return 'ehealthcare_solutions_action';
        case 'AddPluginEpocrates':
          return 'epocrates_action';
        case 'AddPluginHaymarket':
          return 'haymarket';
        case 'AddPluginHealthcasts':
          return 'healthcasts';
        case 'AddPluginMDedge':
          return 'mdedge_action';
        case 'AddPluginMedPageToday':
          return 'medpage_today_banner_action';
        case 'AddPluginMDLinx':
          return 'mdlinx_action';
        case 'AddPluginMedscape':
          return 'medscape_action';
        case 'AddPluginMngHealth':
          return 'mng_health_action';
        case 'AddPluginOptimizeRx':
          return 'optimizerx_banner_action';
        case 'AddPluginPulsePointHCP365':
          return 'pulsepoint_hcp365_action';
        case 'AddPluginPulsePointLife':
          return 'pulsepoint_life_banner_action';
        case 'AddPluginReachMD':
          return 'reachmd_action';
        case 'AddPluginRxNT':
          return 'rxnt_banner_action';
        case 'AddPluginSermo':
          return 'sermo_action';
        case 'AddPluginVeradigm':
          return 'veradigm_banner_action';
        case 'AddPluginVuMedi':
          return 'vumedi_action';
        case 'AddPluginWebMD':
          return 'webmd_action';
        case 'AddPluginDoceree':
          return 'doceree';
        default:
          return null;
      }
    },
    getSpecificPluginFileExtensionOptions(componentName) {
      switch (componentName) {
        case 'AddPluginBioPharm':
        case 'AddPluginDoximity':
        case 'AddPluginEpocrates':
        case 'AddPluginMDedge':
        case 'AddPluginMedscape':
        case 'AddPluginMngHealth':
        case 'AddPluginReachMD':
        case 'AddPluginWebMD':
          return [
            { id: 'txt', text: 'Pipe-Delimited Text File (.txt)' },
          ];
        default:
          return [
            { id: 'csv', text: 'Comma-Separated Values (.csv)' },
            { id: 'txt', text: 'Text File (.txt)' },
          ];
      }
    },
    async installPlugin(expectedImportConfig, returnResponse) {
      if (!await this.$refs.autoImportConfigs.validate()) {
        this.$store.commit('snackbar/showMessage', {
          content: 'Please fix the errors in all Import Configurations',
          color: 'error',
        });

        return;
      }

      const pluginConfiguration = this.getFormattedPluginConfiguration(expectedImportConfig);

      let response = null;
      if (this.installed) {
        response = await this.$rest.plugins
          .put_resource(this.id, {
            isActive: true,
            pluginConfiguration,
          })
          .catch(error => {
            let error_message = null;
            let errors_list = "";
            if (error.response?.data?.errors?.length) {
              error.response.data.errors.forEach(item => {
                errors_list += (item.error + "\r \n");
              });
              error_message = errors_list;
            } else if (error.response?.data?.message) {
              error_message = error.response?.data?.message;
            }

            this.$store.commit('snackbar/showMessage', {
              content: error_message || 'Failed when trying to configure plugin',
              color: 'error',
            });
          });
      } else {
        response = await this.$rest.plugins
          .post_resource({
            plugin: +this.id,
            isActive: true,
            pluginConfiguration,
          })
          .then((resp) => {
            const selected_account = this.$store.getters["user/account"];
            this.$store.commit("user/update_selected_account", {
              ...selected_account,
              activePlugins: [...selected_account.activePlugins, resp.data],
            });

            return resp;
          })
          .catch(error => {
            let error_message = null;
            let errors_list = "";
            if (error.response?.data?.errors?.length) {
              error.response.data.errors.forEach(item => {
                errors_list += (item.error + "\r \n");
              });
              error_message = errors_list;
            } else if (error.response?.data?.message) {
              error_message = error.response?.data?.message;
            }

            this.$store.commit('snackbar/showMessage', {
              content: error_message || 'Failed when trying to install plugin',
              color: 'error',
            });
          });
      }

      if (returnResponse) {
        return response;
      }
      if (!response?.data) {
        return;
      }

      this.$store.commit('snackbar/showMessage', {
        content: this.installed
          ? 'Plugin configured successfully'
          : 'Plugin installed successfully',
        color: 'success',
      });

      await this.$router.push({ name: 'Plugins' });
    }
  },
};
</script>

<style scoped></style>
